import Model from '~/models/Model'
import { Message } from 'element-ui'
import handleError from '~/helpers/handleError'
import store from '~/vuex/store'
import getUserType from '~/helpers/getUserType'

export default class Freelancer extends Model {
  constructor (id, data = {}, fetch = true) {
    super(id, data, fetch)
    this.hiring = false

    // check subscription status if current user is employer
    if (getUserType() === 'employer') {
      store.dispatch('subscription/checkSubscriptionName')
    }
  }

  static get resource () {
    return 'freelancers'
  }

  static get resourceWrapped () {
    return true
  }

  hire () {
    // if on free plan, do nothing
    if (store.getters['subscription/isOnFreePlan']) {
      return
    }

    this.hiring = true
    this.axios.post('hire').then(response => {
      this.hiring = false
      this.data = response.data.data
      Message.success({ message: 'The freelancer has now been marked as hired.', offset: 150 })
    }).catch(handleError)
  }
}
