<template>
  <div class="form">
    <el-form>
      <el-row :gutter="20" class="">
        <el-col :span="24">
          <p>Please upload a professional portrait that clearly shows your face.</p>
        </el-col>
        <div>
          <profile-photo-uploader ref="uploader"
                                  @upload="clearErrorMessage"
                                  @clearErrorMessage="clearErrorMessage" />
        </div>
      </el-row>
      <br>
      <el-row type="flex" justify="center">
        <el-col :xs="20" :lg="12">
          <error-alert :errors="errors" class="signup-alert" />
        </el-col>
      </el-row>
      <el-row type="flex" class="row-bg buttons mt-4" justify="space-between">
        <el-col :span="8">
          <el-form-item class>
            <el-button type="info" @click="back">Back</el-button>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item class="text-right">
            <el-button type="success" @click="submit">
              Submit
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import ErrorAlert from '~/components/ErrorAlert.vue'
import ProfilePhotoUploader from '~/components/Freelancer/ProfilePhotoUploader.vue'
import { LOCAL_BASE_URL } from '~/vuex/utils/constant'
export default {
  components: {
    ErrorAlert,
    ProfilePhotoUploader,
  },
  props: ['browser'],
  data () {
    return {
      url: LOCAL_BASE_URL,
      errors: {},
    }
  },

  methods: {
    back () {
      this.$emit('back')
    },

    submit () {
      const Profile = this.$refs.uploader.Profile

      if (localStorage.getItem('uploadPhoto') == 0) {
        throw this.$set(this.errors, 'upload_photo', ['Click the Upload button to save your photo'])
      }
      // if user already has a profile photo, allow next
      if (Profile.data.profile_photo || this.browser.mobile || this.browser.tablet) {
        localStorage.removeItem('uploadPhoto'); // remove the uploading status in localstorage
        this.$emit('submit')
      } else {
        // else, display error message
        this.$set(this.errors, 'profile_photo', ['Profile Photo'])
      }
    },

    clearErrorMessage () {
      this.$delete(this.errors, 'profile_photo')
      this.$delete(this.errors, 'upload_photo')
    },
  },
}
</script>
