<template>
  <div class="form bio">
    <bio-and-pricing-form :hide-bio="! showBioFields" :profile="Profile.data" />
    <p class="small mb-5"><span class="text-danger">*</span> Required</p>
    <bio-and-pricing-errors :errors="Profile.errors" class="signup-alert" />
    <el-form>
      <el-row type="flex" class="row-bg buttons" justify="space-between">
        <el-col :span="6">
          <el-form-item class>
            <el-button type="info" @click="back">Back</el-button>
          </el-form-item>
        </el-col>
        <el-col :span="6">
          <el-form-item class="text-right">
            <el-button :loading="proceeding" :disabled="proceeding" type="success" @click="next">
              Next
            </el-button>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
  </div>
</template>

<script>
import BioAndPricingForm from '~/components/Freelancer/BioAndPricing/Form.vue'
import BioAndPricingErrors from '~/components/Freelancer/BioAndPricing/Errors.vue'
import MyFreelancerProfile from '~/models/MyFreelancerProfile'

export default {
  components: {
    BioAndPricingForm,
    BioAndPricingErrors,
  },

  data () {
    return {
      Profile: new MyFreelancerProfile('bio'),
      showBioFields: true,
      proceeding: false,
    }
  },

  methods: {
    handleWindowResize () {
      this.showBioFields = window.innerWidth > 720
    },

    created () {
      window.addEventListener('resize', this.handleWindowResize)
    },

    destroyed () {
      window.removeEventListener('resize', this.handleWindowResize)
    },

    back () {
      this.$emit('back')
    },

    next () {
      this.proceeding = true
      this.Profile.saveBio({ successMessage: false })
        .then(() => {
          this.$emit('next')
          this.proceeding = false
        })
        .catch(() => {
          this.proceeding = false
        })
    },
  },
}
</script>
