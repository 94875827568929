<template>
  <div class="skill-search">
    <el-row>
      <el-col :md="24" :xs="24">
        <my-skills ref="mySkills" />
      </el-col>
    </el-row>

    <el-row type="flex" class="row-bg buttons skill-button" justify="space-between">
      <el-col :span="6">
        <el-button type="info" @click="back">Back</el-button>
      </el-col>
      <el-col :span="6" class="text-left">
        <el-button class="text-left" type="success" @click="next">Next</el-button>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import MySkills from '~/components/Skills/MySkills'

export default {
  components: { MySkills },

  methods: {
    back () {
      this.$emit('back')
    },
    next () {
      const mySkills = this.$refs.mySkills

      // check skills limit
      mySkills.checkSkillLimit()

      // check skills rating
      mySkills.checkSkillRating()

      if (! mySkills.skillsLimitError && ! mySkills.skillsRatingError) {
        this.$emit('next')
      }
    },
  },
}
</script>
<style lang="scss" scoped>
    .skill-button {
        padding-bottom: 40px;
    }
    .skill-search {
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 20px;
    }
    .text-left {
        float: right;
    }
    .my-autocomplete {
        li {
            line-height: normal;
            padding: 7px;

            .value {
                text-overflow: ellipsis;
                overflow: hidden;
            }
            .link {
                font-size: 12px;
                color: #b4b4b4;
            }
        }
    }
    .el-collapse,
    .search .el-collapse-item__wrap,
    .search .el-collapse-item__header {
        border: none;
    }
    .search {
        margin-bottom: 5px;
    }
    .search .el-card__body {
        padding: 0 20px;
    }
    .clickHere {
        cursor: pointer;
        color: #409eff;
    }
    .error-text {
        color:red;
        font-size:12px;
    }
</style>
