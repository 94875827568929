import Model from '~/models/Model'

export default class Freelancer extends Model {
  constructor (id) {
    super(id, {
      password: '',
      agreement: false,
      company: {},
    })
  }

  static get resource () {
    return 'signup/freelancer'
  }

  static location () {
    return this.axios().get('location')
  }

  static browser() {
    return this.axios().get('browser')
  }
}
