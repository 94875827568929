<template>
  <div class="signup">
    <div class="signup-body">
      <div class="container">
        <div class="row justify-content-md-center">
          <div class="text-center col-12 pt-4 pt-lg-0">
            <h5 class="mb-0 mb-sm-4 signup-header">Freelancer Signup</h5>
          </div>
          <div class="steps col-md-10 col-lg-7 p-0 mb-4">
            <div class="d-none d-sm-block">
              <el-steps :active="step - 1" align-center>
                <el-step title="Step 1" description="Name and email" />
                <el-step title="Step 2" description="Skills" />
                <el-step title="Step 3" description="Bio and pricing" />
                <el-step title="Step 4" description="Photo" />
              </el-steps>
            </div>
          </div>
          <div class="content col-md-10 col-lg-7 p-0">
            <div v-show="step == 1">
              <div class="header"><h3>Name and email</h3></div>
              <hr />
              <name-and-email @next="next" />
            </div>
            <div v-if="step == 2">
              <div class="header"><h3>Skills</h3></div>
              <hr />
              <skills @next="next" @back="back" />
            </div>
            <div v-if="step == 3">
              <div class="header"><h3>Bio and Pricing</h3></div>
              <hr />
              <bio-and-pricing @next="next" @back="back" />
            </div>
            <div v-if="step == 4">
              <div class="header">
                <h3>Photo Upload</h3>
              </div>
              <hr />
              <photo-upload @back="back" @submit="submit" :browser="browser"/>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Skills from '~/components/SignUp/Freelancer/Skills.vue';
import NameAndEmail from '~/components/SignUp/Freelancer/NameAndEmail.vue';
import BioAndPricing from '~/components/SignUp/Freelancer/BioAndPricing.vue';
import PhotoUpload from '~/components/SignUp/Freelancer/PhotoUpload.vue';
import Freelancer from '~/models/Freelancer';
import FreelancerSignUp from '~/models/SignUp/Freelancer'
import loginFromOnboarding from '~/helpers/loginFromOnboarding';

export default {
  components: {
    Skills,
    NameAndEmail,
    BioAndPricing,
    PhotoUpload,
  },

  data() {
    return {
      steps: ['Name and email', 'Skills', 'Bio and pricing', 'Photo'],
      step: 1,
      browser: {}
    };
  },
  watch: {
    step(newValue) {
      // scroll up on the next step
      this.$nextTick(() => window.scrollTo(0, 0));
      // if step is now visible
      if (newValue === 3) {
        this.$nextTick(() => window.dispatchEvent(new Event('resize')));
      }
    },
    '$route.query.step': function(newValue) {
      this.step = newValue || 1;
    },
  },
  created() {
    FreelancerSignUp.browser().then(response => {
      this.browser = response.data
    })
  },
  methods: {
    // ...mapActions({
    //   create: 'freelancerCreate',
    //   create_next: 'freelancerCreateNext',
    //   generateToken: 'authentication',
    // }),

    routerPush() {
      if (this.step == 1) {
        this.$router.push({path: `freelancersignup`});
      } else {
        this.$router.push({path: `freelancersignup`, query: {step: this.step}});
      }
    },

    next() {
      ++this.step
      this.routerPush()
    },

    back() {
      --this.step
      this.routerPush()
    },

    goTo(step) {
      this.step = step;
    },

    submit() {
      Freelancer.find('my-profile').then(response => loginFromOnboarding(response.data.data, 'freelancer'));
    },
  },
};
</script>

<style lang="scss">
.signup-mobile .signup-nav {
  border-radius: 0;
}

#special-char.has_error {
  /*color: gray;*/
}
#special-char.validated {
  color: green !important;
}
#special-char.has_error:before {
  content: '\e79c';
}
.signup-alert {
  max-width: 400px;
  width: 100%;
  margin: 0 auto 25px;
  font-weight: bold;
}
.signup-alert ul {
  margin-bottom: 5px;
}
.step1-required label.el-form-item__label:after {
  content: '*';
  color: #f56c6c;
  margin-left: 4px;
}

.signup {
  .el-select {
    width: 100%;
  }
  a {
    color: inherit;
  }
  .el-button--text:focus,
  .el-button--text:hover {
    color: #333;
  }
  .terms {
    color: inherit;
    text-decoration: underline;
  }
  .terms:active,
  .terms:focus {
    outline: 0;
  }
  .signup-mobile .el-button {
    // padding: 15px 20px;
    // font-size: 16px;
    background-color: #e87722;
    font-weight: bold;
    box-shadow: #979797 0 2px 4px 0;
  }

  .el-dropdown-menu {
    width: 100%;
  }

  .el-step.is-center .el-step__description {
    padding-left: 10%;
    padding-right: 10%;
  }

  .form .el-form-item__error {
    font-size: 10px;
  }

  .el-step__icon {
    background-color: #f7f7f7;
  }

  .el-step__head.is-finish {
    color: #e87722;
    border-color: #e87722;
  }

  .is-process .el-step__icon.is-text {
    background: #e87722;
    color: white;
    border-color: #e87722;
  }

  .el-step__title.is-process,
  .el-step__description.is-process,
  .el-step__title.is-finish,
  .el-step__description.is-finish {
    color: #e87722;
  }

  .el-form-item {
    margin-bottom: 15px;
  }

  .el-checkbox {
    margin-bottom: 0px;
  }

  .el-form-item__label {
    margin-bottom: 0;
  }

  .el-button--success {
    color: #fff;
    background-color: #91c145;
    border-color: #91c145;
  }

  .el-step__head.is-finish .el-step__line {
    background-color: #e87722;
  }

  .el-step__description.is-process {
    font-weight: bold;
  }

  .avatar-uploader {
    text-align: center;
  }

  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }

  .avatar-uploader .el-upload:hover {
    border-color: #e87722;
  }

  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }

  .el-upload:hover .avatar-uploader-icon {
    color: #e87722;
  }

  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }

  .el-step__head {
    cursor: pointer;
  }

  /* Customization */
  ul li {
    list-style: none;
    margin-left: -33px;
    padding-bottom: 1px;
  }

  .password-strength {
    padding-left: 33px;
    margin-top: 0;
  }

  .password-strength span {
    color: #8e8f92;
    font-size: 11px;
    margin-left: -30px;
  }

  .password-strength ul {
    list-style-type: none;
    padding-left: 0px;
    line-height: 1rem;
    font-size: 10px;
  }

  .password-strength .has_error {
    color: red;
  }

  .password-strength .validated {
    color: green;
  }

  .password-strength li {
    padding-left: 13px;
    position: relative;
  }

  .password-strength li:before {
    content: '\e79d';
    display: table;
    font-family: 'element-icons' !important;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    left: 0;
    line-height: 1;
    position: absolute;
    speak: none;
    text-transform: none;
    top: 3px;
    vertical-align: baseline;
  }

  .password-strength li.validated:before {
    content: '\e79c';
  }

  .validation-enter-active,
  .validation-leave-active {
    transition: opacity 0.5s;
  }
  .validation-enter, .validation-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
  }
  // .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap>.el-form-item__label:before, .el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:before {
  //   content: '';
  //   margin-right: 0;
  // }
}

.bio {
  p.main {
    font-size: 14px;
    color: #333;
    //color: #8F8F8F;
    font-weight: bold;
  }
  .description {
    font-size: 13px;
    color: #333;
    font-weight: normal;
    line-height: 26px;
  }
  ul li {
    list-style: disc;
    margin-left: 0;
  }
  .price-range .el-input {
    width: 100%;
  }
  .price-range .el-checkbox {
    line-height: 40px;
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .terms {
    font-size: 12px;
  }
  .signup-body {
    padding: 0 0 20px 0 !important;
  }
  .signup-body .form {
    padding: 10px 15px 15px 15px !important;
  }
  .signup {
    .el-checkbox__label {
      font-size: 12px;
      white-space: normal;
    }

    .header {
      display: none;
    }
    .row.justify-content-md-center {
      padding: 0 15px;
    }
    .terms {
      padding: 0;
    }
  }
  .el-dropdown-menu__item {
    text-align: center !important;
  }
  .el-dropdown-menu.el-popper {
    border-radius: 0 !important;
    margin: 0 !important;
    width: 100%;
    left: 0 !important;
  }
  .popper__arrow {
    display: none !important;
  }
}
@media (max-width: 375px) {
  .signup .terms-checkbox .el-checkbox__input {
    margin-bottom: 20px;
  }
}
</style>
